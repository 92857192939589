@import 'master';

@media print {
  html,
  body,
  body app-root {
    overflow: visible;
  }

  toast {
    display: none;
  }

  @page {
    margin: 0;
    size: letter; // in Chrome, appears to be limiting browser dialog paper size choice to only default letter
  }

  body {
    margin: 0;
    -webkit-print-color-adjust: exact !important;
  }
}

.print-page {
  page-break-after: avoid;
  page-break-inside: avoid;
  min-height: var(--print-page-height);
  width: var(--print-page-width);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
