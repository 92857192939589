/* Ideally we stick to the convention where every background has a constrasting foreground color.
   Then thru out code you can always feel secure just matching them up by the number

   For Transparency colors: the ThemeService will automatically create high, medium, and low values for EVERY color setting with a UIName
   Check the ColorTransparencyModifiers config for more detail
*/
:root {
  /* Special cases in Theme Service */
  --primaryfont: Raleway;

  --headerfont: Raleway;
  --headerborder: #29abe2;
  --headerfontuppercase: none;

  --accordionheaderfontweight: 700;

  --blur-level: 5px;

  /* Color settings with alpha modifiers */
  --background1: #2f323a;
  --foreground1: #fff;

  --background2: #f2f2f2;
  --foreground2: #2f323a;

  --background3: #575a60;
  --foreground3: #ffffff;

  --background4: #29abe2;
  --foreground4: #ffffff;

  --background5: #999999;
  --foreground5: #ffffff;

  --background6: #ffffff;
  --foreground6: #2f323a;

  --background7: #304b68;
  --foreground7: #ffffff;

  --favoritesactive: #f3b544;
  --favoritesinactive: #999999;
  --favoritesforeground: #fff; /* no setting */
  --favorites-border-inactive: #00000000;
  --favorites-border-active: #00000000;
  --favorites-counter-circle-background: var(--background5-alpha-high);
  --favorites-counter-circle-foreground: var(--foreground5);

  --fplistbackground: #f2f2f2;
  --ifpbackground: #f2f2f2;

  --inventory-home-color: var(--background4);
  --inventory-home-foreground: var(--foreground1); /* no setting */

  --icon-background: var(--background4);
  --icon-foreground: var(--foreground4);

  --global-active-background: var(--background4);
  --global-active-foreground: var(--foreground4);

  --navigationbackground: var(--background1); /* primary 1 */
  --navigationtilebackground: var(--background3); /* general 4 */
  --navigationtileforeground: var(--foreground3); /* general 1 */
  --navigationselected: var(--background4); /* primary 2 */
  --navigationselectedforeground: var(--foreground4); /* no setting */
  --navigationselectedforeground-alpha-low: var(--foreground4-alpha-low);
  --navigationselectedforeground-alpha-medium: var(--foreground4-alpha-medium);
  --navigationselectedforeground-alpha-high: var(--foreground4-alpha-high);
  --navigation-selected-icon-foreground: var(--icon-foreground);
  --navigationtitleforeground: var(--foreground1);
  --navigationtilebordercolor: var(--navigationtilebackground);
  --navigationtileborderradius: 0;

  --quick-nav-background: var(--navigationbackground);
  --quick-nav-foreground: var(--foreground1); /* general 1 */
  --quick-nav-selected: var(--navigationselected);
  --quick-nav-selected-foreground: var(--navigationselectedforeground); /* no setting */
  --quick-nav-selected-foreground-alpha-low: var(--navigationselectedforeground-alpha-low);
  --quick-nav-selected-foreground-alpha-medium: var(--navigationselectedforeground-alpha-medium);
  --quick-nav-selected-foreground-alpha-high: var(--navigationselectedforeground-alpha-high);

  --brochureheaderfooterbackground: var(--background1);
  --brochureheaderfooterforeground: var(--foreground1);

  --standardbuttonbackground: var(--background1);
  --standardbuttonforeground: var(--foreground1);
  --standardbuttonbordercolor: var(--background1);
  --prioritybuttonbackground: var(--background7);
  --prioritybuttonforeground: var(--foreground7);

  --modal-background: var(--background1);
  --modal-foreground: var(--foreground1);
  --modal-icon-foreground: var(--foreground1);
  --modal-success-background: #008000;
  --modal-success-foreground: var(--foreground1);
  --modal-success-icon-foreground: var(--foreground1);
  --modal-alert-background: #ff0000;
  --modal-alert-foreground: var(--foreground1);
  --modal-alert-icon-foreground: var(--foreground1);

  --pin-pad-title-bkg: var(--background3);
  --pin-pad-title-fore: var(--foreground3);
  --pin-pad-body-bkg: var(--background1);
  --pin-pad-body-fore: var(--foreground1);
  --pin-pad-button-bkg: var(--background7);
  --pin-pad-button-fore: var(--foreground7);

  --gallery-ctrl-bkg: var(--background1);
  --gallery-ctrl-fullscreen-bkg: var(--background3);
  --gallery-button-bkg: var(--standardbuttonbackground);
  --gallery-button-foreground: var(--standardbuttonforeground);

  /* Non-color settings */
  --border-radius-accordions: 0;
  --border-radius-buttons: 0;
  --border-radius-cards: 0;
  --border-radius-modals: 0;
  --border-radius-quick-nav: 0;
  --border-radius-numeric-input-buttons: 0;

  --boxshadowaccordion: 0 0 4px 2px #00000026;
  --boxshadowcards: 0 0 4px 2px #00000026;
  --boxshadowbuttons: 0 0 4px 1px #00000026;
  --box-shadow-inputs: 0 0 4px 1px #00000026;

  --print-brochure-image-fit: cover;

  /*****************************************************************************************/
  /* Other variables - NOT settings */
  --slide-out-menu-standard-width: 25vw;
  --current-slide-out-menu-width: 25vw; /* value updated via Theme Service */

  --floating-page-title-height: 3rem;
  --current-floating-page-title-height: 3rem; /* value updated via Theme Service */

  --transparent-gray: #44444499;
  --bottom-nav-height: 60px;
  --box-shadow: 0 0 6px 2px #00000026;
  --modal-backdrop: #0e0e0ede;

  /* print page height of 11in causes page overflow in firefox*/
  --print-page-height: 11in; /* 11in is standard height of "letter" paper size */
  --print-page-width: 8.5in;

  /* Home Configurator Setting Variables */
  --hc-accent1: #3c709d;
  --hc-background1: #ffffff;
  --hc-foreground1: #000000;
  --hc-background2: #f5f5f5;
  --hc-foreground2: #000000;
  --hc-primary-button-background: #3c709d;
  --hc-primary-button-foreground: #ffffff;
  --hc-secondary-button-background: #ffffff;
  --hc-secondary-button-foreground: #030303;
  --hc-navigation-module-background: #3c709d;
  --hc-navigation-module-foreground: #ffffff;
  --hc-accordion-body-background: #f2f2f2;
  --hc-button-box-shadow: 0 0 4px 1px #00000026;
}
