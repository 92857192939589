@import 'master';

// overrides for demo purposes... Hopefully temporary and can either be fully integrated or removed all together

.demo-mode {
  accordion .accordion-container {
    background-color: transparent;
    box-shadow: none;
    background: #41443fd1;
    border-bottom-left-radius: 15px 15px;
    color: white;

    &.open > .accordion-header {
      background: linear-gradient(90deg, rgb(4 89 158) 0%, 70%, transparent 100%);
    }

    .accordion-header {
      background-color: unset;
      transition: background-color 0.4s linear;

      .toggler icon svg {
        fill: #fff;
      }
    }

    .accordion-body {
      border-top: none !important;

      .body-content {
        padding: 0;
      }
    }
  }

  slide-out-menu .menu {
    background-color: transparent;
    box-shadow: none;
    top: 4rem;
    bottom: 0;
    height: auto;

    & > header {
      background-color: transparent;
      padding: 1rem 1rem 0;

      .heading {
        display: none;
      }
    }
  }

  .page-container .content-container .tools-container {
    flex-direction: row-reverse;
    bottom: 1rem;
    z-index: 7;

    & > button {
      &:first-of-type {
        border-bottom-right-radius: 4px;
      }
      &:last-of-type {
        border-top-left-radius: 4px;
      }
    }

    scale-control {
      margin: 0;
      margin-right: 1rem;
      flex-direction: row;
      width: auto;
      border: none;

      #scale-display {
        display: none;
      }

      button {
        &:first-child {
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }

    button {
      background-color: #41443fd1;
      color: #fff;
      width: 45px;
      height: 45px;

      &:hover {
        icon svg {
          fill: lightgray;
        }
      }

      &.selected {
        background-color: rgba(4, 89, 158, 0.9);
      }

      icon svg {
        fill: #fff;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  mat-select {
    color: white;
    background-color: unset;

    &.mat-mdc-select .mat-mdc-select-value-text,
    &.mat-select .mat-select-placeholder,
    & .mat-select-arrow {
      color: white;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    &.mat-mdc-select .mat-mdc-select-trigger {
      border: 1px white solid;
      border-radius: 5px;
    }
  }

  .mat-mdc-select-panel {
    background-color: #41443fd1;
  }

  .mat-mdc-option {
    background-color: #41443fd1 !important;

    &.mat-mdc-selected {
      background: linear-gradient(90deg, rgb(4 89 158) 0%, 70%, transparent 100%) !important;
      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
      .mat-option-text {
        color: #fff;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-option-text {
      color: #fff;
    }
  }

  .alt-side-menu-buttons {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    background-color: #41443fd1;
    transition: background-color 200ms ease;
    border-top-right-radius: 4px;
    opacity: 0.8;

    icon-button {
      font-size: 1rem;
      padding: 1px;
      transform: translateX(0);
      transition: transform 0.4s linear;

      button {
        background-color: unset;
        box-shadow: unset;
        border: unset;
      }

      &.carrot-arrow {
        transform: translateX(0);
        icon {
          transition: transform 0.4s linear;
          transform: rotateY(180deg);
        }
      }
    }

    &.open {
      background-color: rgba(4, 89, 158, 0.9);

      icon-button {
        &.carrot-arrow {
          transform: translateX(2rem);
          icon {
            transform: rotateY(0);
          }
        }

        &:last-child {
          transform: translateX(-2rem);
        }
      }
    }
  }

  .demo-modal {
    width: 400px;
    height: 300px;
    border-radius: 13px;
    background: #41443fd1;
    @include align-both-directions();
    display: flex;
    flex-direction: column;
    animation: fadeIn 200ms ease;

    .modal-header {
      display: flex;
      padding: 1rem;
      align-items: center;
      color: white;
      background: linear-gradient(90deg, rgb(4 89 158) 0%, 70%, transparent 100%);
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;

      .favorite icon svg {
        fill: var(--favoritesactive);
      }

      icon {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;

        svg {
          fill: white;
        }
      }

      .close {
        margin-left: auto;
      }
    }

    .modal-body {
      padding: 1rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;

      .info-and-pic {
        display: flex;
        height: 100%;

        .info {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;

          * + h3 {
            margin-top: 0.5rem;
          }
        }

        .pic {
          width: 50%;
          animation: fadeIn 200ms ease;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}
