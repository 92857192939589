@import 'master';
@import './animations';

body {
  user-select: none;
  font-family: var(--primaryfont), Raleway, sans-serif;

  --tabletMinSize: #{$tabletMinSize};
  --desktopMinSize: #{$desktopMinSize};

  app-root {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
  }
}

@include media('>=tablet') {
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    border: solid 5px transparent;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0.35);
    border: solid 5px transparent;
    border-radius: 1rem;
  }
}

h1 {
  font-family: var(--headerfont), sans-serif;
  text-transform: var(--headerfontuppercase);
}

.panning .svg-container svg {
  symbol,
  use {
    display: none;
  }
}

.shift-up-for-nav {
  slide-out-menu .menu .body {
    padding-bottom: var(--bottom-nav-height) !important;
  }

  .page-container {
    .svg-viewport,
    .compare-plan-container {
      height: calc(100% - var(--bottom-nav-height));
    }

    .map-viewport ~ .tools-container,
    .map-container ~ scale-control,
    #map-container scale-control,
    slideshow .dots {
      bottom: calc(var(--bottom-nav-height) + 1rem);
    }
  }

  visualizer .page-container .content-container .tools-container {
    bottom: calc(var(--bottom-nav-height) + 1rem);
  }

  brochure .page-container,
  navigational-sections .sections-content > section,
  .half-width gallery-slider .slide-body,
  accordion-sections .media-content .content-body,
  accordion-sections .accordions-content {
    padding-bottom: var(--bottom-nav-height);
  }
}

.page-container {
  width: 100%;
  height: 100%;
  background-color: var(--background2);
  color: var(--foreground2);

  .content-container {
    transition: width 300ms ease; // matches slide out menu transition timing
    height: 100%;
    width: calc(100% - var(--slide-out-menu-standard-width));
    position: relative;

    @include media('<desktop') {
      width: 100%;
    }

    &.expanded {
      width: 100%;
    }

    &.float-right {
      float: right;
    }

    .tools-container {
      @include absolute(right 2rem bottom 2rem);
      display: inline-flex;
      flex-direction: column;

      scale-control {
        margin-top: 2rem;
      }

      & > button {
        @include myscpToolsQuickButton;
      }
    }

    scale-control > button,
    .tools-container > button {
      &:hover:not(.selected) {
        icon svg {
          fill: var(--global-active-background-alpha-high);
        }
      }
    }
  }
}

// intended for use with FP Rules and FloorArtService
.svg-container {
  svg {
    .on {
      display: block !important;
    }

    .off {
      display: none;
    }
  }
}

svg {
  & > :not([id^='Lots']) {
    pointer-events: none;
  }

  #Lots > g,
  [id^='Lots_'] > g {
    cursor: pointer;

    [id*='-dims'],
    [id*='_dims'] {
      display: none;
    }

    &.bright {
      text,
      text tspan {
        fill: #fff !important;
      }

      [id^='TEXT'] {
        path {
          fill: #fff !important;
        }
      }
    }

    &:focus {
      outline: 2px solid black;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }
}

svg g[id$='secondarysymbol'] {
  display: none;
}

.using-secondary-symbols svg g[id$='secondarysymbol'] {
  display: block;
}

.blocking-overlay-backdrop {
  background-color: var(--transparent-gray);
  pointer-events: auto !important;
  backdrop-filter: blur(var(--blur-level));
}

.hidden {
  display: none;
}

.flex {
  display: flex;

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }

  &.allow-wrap {
    flex-wrap: wrap;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.text-right {
  text-align: right;
}

.push-right {
  margin-left: auto;
}

.padding-1rem {
  padding: 1rem;
}
.padding-2rem {
  padding: 2rem;
}

.height-100 {
  height: 100%;
}

icon,
ml-icon {
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

ul.with-bullets {
  list-style: disc;
  padding-left: 1.5rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
input,
select,
textarea,
mat-select {
  background-color: var(--background6);
  color: var(--foreground6);
  border: none;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: var(--border-radius-buttons);
  box-shadow: var(--box-shadow-inputs);
}

// padding directly on mat-select causes options dropdown to skew left, apply padding to trigger instead
mat-select {
  padding: 0;

  .mat-mdc-select-trigger {
    padding: 0.5rem;
  }
}

input[type='checkbox'] {
  box-shadow: none;
}

.selectable {
  cursor: pointer;
}

button {
  cursor: pointer;

  &:active:not([disabled], .disabled) {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:focus {
    outline: 2px solid #000; // needs to be something very visible for accessibility -- helps when user is just tabbing thru page
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

[border-optional] {
  &.border-top {
    border-top: 10px solid var(--headerborder);
    padding-top: 0;
  }

  &.border-bottom {
    border-bottom: 10px solid var(--headerborder);
    padding-bottom: 0;
  }

  &.border-all {
    border: 10px solid var(--headerborder);
    padding: 0.5rem;
  }
}

.counter {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  @include flex-center-center();
  background-color: var(--background2);
  color: var(--foreground2);
  @include animation-fadeIn();
  flex-shrink: 0;
}

.counter {
  &.active {
    background-color: var(--global-active-background-alpha-low);
  }
}

@media only screen and (max-width: 1200px) {
  :root {
    --slide-out-menu-standard-width: 33vw;
  }
}

.mat-mdc-select-panel {
  // important needed to override the material style tag
  @supports (overflow: overlay) {
    overflow: overlay !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: var(--border-radius-modals);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-modals);
  }

  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--box-shadow-inputs);
  }
}

mat-select {
  &.mat-mdc-select {
    font-family: var(--primaryfont);
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    &.mat-select-disabled {
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow {
        opacity: 0.3;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-select-value-text,
    .mat-select-placeholder {
      font-size: 1em;
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text,
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #000;
}

.mat-mdc-option {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    font-size: 1em;
  }
}

body .mat-mdc-tooltip {
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  overflow: visible;
  white-space: pre-line;
  margin: 10px;
}

// Google Map "2 finger scroll" warning to be above svg overlay
.gm-style-pbc {
  z-index: 5 !important;
  pointer-events: none;
}
